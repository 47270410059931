export default class ApiError extends Error  {
  status: number
  message: any

  constructor(message: string, status: number, ) {
    super(message)
    this.message = message
    this.name = "ApiError"
    this.status = status
  }

  toString(): string {
    return this.message.toString()
  }
}
