// import vendor libs
import "../utils/jquery"
import 'bootstrap'
import 'responsively-lazy/responsivelyLazy.js'


import "../components/modal"
import "../utils/aosInit"
import CryptoInfoSection from "../sections/CryptoInfoSection"

CryptoInfoSection.init().startPolling()
