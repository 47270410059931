import axios, {AxiosInstance} from 'axios'
import ApiError from './ApiError'

export default class ApiClass {
  private readonly axiosClient: AxiosInstance

  constructor(baseURL: string) {
    this.axiosClient = axios.create({
      baseURL: baseURL,
      timeout: 1000,
      headers: {
        'Accept': 'application/json',
        'content-type': 'application/json',
      },
      responseType: 'json'
    })

    this.axiosClient.interceptors.response.use(
      response => {
        if (Object.prototype.hasOwnProperty.call(response, 'data'))
          return response.data
        return response
      },
      error => {

        if (!Object.prototype.hasOwnProperty.call(error, 'response'))
          throw new ApiError('Unhandled error happened...', 400)

        const status = error.response.status
        const errData = error.response.data

        if (Object.prototype.hasOwnProperty.call(errData, 'message'))
          throw new ApiError(errData.message, status)
        if (Object.prototype.hasOwnProperty.call(errData, 'error'))
          throw new ApiError(errData.error, status)
        throw new ApiError('Unhandled error happened...', status)
      })
  }

  async get(url: string, params?: any): Promise<any> {
    const data = await this.axiosClient({
      method: 'get',
      url,
      params,
    })
    if (Object.prototype.hasOwnProperty.call(data, 'data'))
      return data.data
    return data
  }

  async post(url: string, data?: any): Promise<any> {
    const dataRes = await this.axiosClient({
      method: 'post',
      url,
      data
    })
    if (Object.prototype.hasOwnProperty.call(data, 'data'))
      return dataRes.data
    return dataRes
  }
}
