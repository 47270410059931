import api from '../api'
import { CryptoItem } from '../models'
import { poll } from '../utils/polling'

const blacklist = ['OKB', 'GNS', 'KBC']

export default class CryptoInfoSection {
	private static _instance: CryptoInfoSection
	private data: CryptoItem[] = null

	private constructor(private $wrapper: JQuery) {
		this.fetchAndRender()
		// setInterval(this.fetchAndRender.bind(this), 10000)
	}

	public static init(): CryptoInfoSection {
		if (!this._instance)
			this._instance = new this($('[data-js="crypto-wrapper"]'))
		return this._instance
	}

	public static fetchData(): Promise<CryptoItem[]> {
		return api.get('/quotes/cmc').then(res => Object.entries(res).map(([key, value]) => ({
			symbol: key,
			price: parseFloat(value as any),
		})))
	}

	private static singleItemHtml({ symbol, price }: CryptoItem): string {
		const priceStr = '$' + price.toFixed(3)

		return `
<div class="crypto-api__cryptos-item" id="${symbol}">
  <img src="/assets/images/tokens/${symbol}.svg" class="crypto-api__cryptos-item__img" alt="${symbol}"/>
  <div class="crypto-api__cryptos-item__name">${symbol}</div>
  <div class="crypto-api__cryptos-item__price">${priceStr}</div>
</div>
    `
	}

	public startPolling(): void {
		poll(this.fetchAndRender.bind(this), 5000)
	}

	private fetchAndRender(): Promise<void> {
		return CryptoInfoSection.fetchData()
			.then(data => {
				this.data = data
				this.render()
			})
	}

	private render(): void {
		this.$wrapper.empty()
		this.data.filter(({symbol}) => !blacklist.includes(symbol)).forEach(item => {
			const html = (CryptoInfoSection.singleItemHtml(item))
			this.$wrapper.append(html)
		})
	}
}
